import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { getUUID } from "../utils/stringUtils";
import { exportModel } from "../utils/jsonUtils";
import {
    ExportModelProps,
    ExportModelErrorType,
} from "../types/ExportModelPropsType";

const ExportModel: React.FC<ExportModelProps> = ({ formValues }) => {
    const initError: ExportModelErrorType = {
        fileNameError: null,
        modalNameError: null,
    };
    const [visible, setVisible] = useState(false);
    const [fileName, setFileName] = useState("");
    const [modalName, setModalName] = useState("");
    const [error, setError] = useState<ExportModelErrorType>(initError);

    const handleFileNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^[a-zA-Z0-9.\-_]*$/.test(value) && value.length <= 255) {
            setFileName(value);
        }
        if (error.fileNameError !== null && value.length > 0) {
            const newError: ExportModelErrorType = { ...error };
            newError.fileNameError = null;
            setError(newError);
        }
    };

    const handleNamenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length <= 255) {
            setModalName(value);
        }
        if (error.modalNameError !== null && value.length > 0) {
            const newError: ExportModelErrorType = { ...error };
            newError.modalNameError = null;
            setError(newError);
        }
    };

    const handleSubmitExport = () => {
        const newError: ExportModelErrorType = { ...initError };
        if (fileName.length > 0 && modalName.length > 0) {
            const modal: ModelType = {
                name: modalName,
                code: `modal-${getUUID()}`,
                values: formValues,
            };
            exportModel(modal, fileName + ".json");
            setVisible(false);
            setFileName("");
            setModalName("");
        }
        if (fileName.length < 1) {
            newError.fileNameError = "Fájlnevet kötelező kitölteni!";
        }
        if (modalName.length < 1) {
            newError.modalNameError = "Modellnevet kötelező kitölteni!";
        }
        setError(newError);
    };

    return (
        <>
            <Button
                label="Exportálás"
                severity="help"
                icon="pi pi-file-export"
                onClick={() => setVisible(true)}
            />
            <Dialog
                header="Exportálás"
                visible={visible}
                className="w-11 md:w-6"
                onHide={() => setVisible(false)}
                blockScroll={true}
            >
                <div className="card mt-3">
                    <div className="mb-3">
                        <label
                            className="font-bold block mb-2"
                            htmlFor="fileName"
                        >
                            Fájlnév (kiterjesztés nélkül)
                        </label>
                        <div className="p-inputgroup flex-1">
                            <InputText
                                id="fileName"
                                name="fileName"
                                value={fileName}
                                onChange={handleFileNameChange}
                                className={
                                    "w-full mb-0" +
                                    (error.fileNameError !== null
                                        ? " p-invalid"
                                        : "")
                                }
                                required
                            />
                            <span className="p-inputgroup-addon">.json</span>
                        </div>
                        <small className="text-sm">{fileName.length}/255</small>
                        {error.fileNameError !== null ? (
                            <div className="tex-sm text-red-500 mt-1">
                                {error.fileNameError}
                            </div>
                        ) : null}
                    </div>
                    <div className="mb-3">
                        <label
                            className="font-bold block mb-2"
                            htmlFor="modalName"
                        >
                            Modellnév
                        </label>
                        <InputText
                            id="modalName"
                            name="modalName"
                            value={modalName}
                            onChange={handleNamenChange}
                            className={
                                "w-full mb-0" +
                                (error.modalNameError !== null
                                    ? " p-invalid"
                                    : "")
                            }
                            required
                        />
                        <small className="text-sm">
                            {modalName.length}/255
                        </small>
                        {error.modalNameError !== null ? (
                            <div className="tex-sm text-red-500 mt-1">
                                {error.modalNameError}
                            </div>
                        ) : null}
                    </div>
                    <Button
                        label="Exportálás"
                        icon="pi pi-file-export"
                        onClick={handleSubmitExport}
                    />
                </div>
            </Dialog>
        </>
    );
};

export default ExportModel;
