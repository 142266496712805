import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import FormValuesType from "../types/FormValuesType";
import CalculatedValuesType from "../types/CalculatedValuesType";
import NumericInput from "../components/NumericInput";
import NumericLabel from "../components/NumericLabel";
import MultiProgressBar from "../components/MultiProgressBar";
import ExportModel from "../components/ExportModel";
import ImportModel from "../components/ImportModel";
import { Fieldset } from "primereact/fieldset";
import { InputSwitchChangeEvent } from "primereact/inputswitch";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { formatQty, formatHuf, arabicToRoman } from "../utils/stringUtils";
import packageJson from "../../package.json";
import orginalModelsData from "../datas/models.json";
import maxPriceCashOnDelivery from "../datas/maxPriceCashOnDelivery.json";

const App: React.FC = () => {
    const modelsDataWithIndex = (modelsData: ModelType[]): ModelType[] =>
        modelsData.map((item, index) => ({
            ...item,
            name: `${arabicToRoman(index + 1)}. ${item.name}`,
        }));

    const [modelsData, setModelsData] = useState<ModelType[]>(
        modelsDataWithIndex(orginalModelsData)
    );

    const [isModelChanged, setIsModelChanged] = useState<boolean>(false);

    const [formValues, setFormValues] = useState<FormValuesType>(
        modelsData[0].values
    );
    const [selectedModel, setSelectedModel] = useState<ModelType>(
        modelsData[0]
    );

    const isMult = () => formValues.isQuantity && formValues.quantity > 1;
    const multByQty = (value: number): number =>
        isMult() ? value * formValues.quantity : value;

    const calculate = (): CalculatedValuesType => {
        const netImportPriceHuf =
            formValues.netImportPriceEur * formValues.euroExchangeRate;
        const netRetailPrice = Math.floor(formValues.grossRetailPrice / 1.27);
        const grossTransferPrice = Math.floor(
            formValues.netTransferPrice * 1.27
        );
        const creditCardPayment =
            formValues.creditCardPayment !== 0
                ? formValues.creditCardPayment
                : Math.ceil(
                      multByQty(
                          formValues.isTransferPrice
                              ? grossTransferPrice
                              : formValues.grossRetailPrice
                      ) *
                          0.015 +
                          85
                  );
        const costs =
            formValues.packing +
            formValues.shipping +
            (formValues.isCashOnDelivery ? formValues.cashOnDelivery : 0) +
            (formValues.isBonusProduct ? formValues.bonusProduct : 0) +
            (formValues.isCreditCardPayment ? creditCardPayment : 0);
        const sumCommissions =
            (formValues.isReferralCommission
                ? formValues.referralCommission
                : 0) +
            (formValues.isAcquisitionCommission
                ? formValues.acquisitionCommission
                : 0) +
            (formValues.isAcquisitionManagerCommission
                ? formValues.acquisitionManagerCommission
                : 0) +
            (formValues.isMaintenanceCommission
                ? formValues.maintenanceCommission
                : 0) +
            (formValues.isMaintenanceManagerCommission
                ? formValues.maintenanceManagerCommission
                : 0);
        const netProfit =
            (formValues.isTransferPrice
                ? formValues.netTransferPrice
                : netRetailPrice) -
            netImportPriceHuf -
            sumCommissions -
            costs;
        const systemCost = netProfit > 0 ? Math.floor(netProfit * 0.2) : 0;
        const sumCosts = costs + systemCost;
        const finalNetProfit = netProfit - systemCost;
        const netCommercialMargin = formValues.isTransferPrice
            ? netRetailPrice - formValues.netTransferPrice
            : 0;
        const netCommercialMarginEur = formValues.isTransferPrice
            ? Math.floor(netCommercialMargin / formValues.euroExchangeRate)
            : 0;
        const commercialMarginPercent = formValues.isTransferPrice
            ? Math.ceil(
                  (netRetailPrice / formValues.netTransferPrice - 1) * 100
              )
            : 0;
        const multNetImportPriceHuf = multByQty(netImportPriceHuf);
        const multCosts =
            formValues.packing +
            formValues.shipping +
            (formValues.isCashOnDelivery ? formValues.cashOnDelivery : 0) +
            (formValues.isCreditCardPayment ? creditCardPayment : 0) +
            multByQty(formValues.isBonusProduct ? formValues.bonusProduct : 0);
        const multSumCommissions = multByQty(sumCommissions);
        const multNetProfit =
            multByQty(
                formValues.isTransferPrice
                    ? formValues.netTransferPrice
                    : netRetailPrice
            ) -
            multNetImportPriceHuf -
            multSumCommissions -
            multCosts;
        const multSystemCost =
            multNetProfit > 0 ? Math.floor(multNetProfit * 0.2) : 0;
        const multSumCosts = multCosts + multSystemCost;
        const multFinalNetProfit = multNetProfit - multSystemCost;
        const tooltipSumCosts = isMult()
            ? "+Csomagolás:1 * " +
              formatHuf(formValues.packing) +
              "<br/>+Szállítási díj:1 * " +
              formatHuf(formValues.shipping) +
              (formValues.isCreditCardPayment
                  ? "<br/>+Bankkártyás fizetés:1 * " +
                    formatHuf(creditCardPayment)
                  : "") +
              (formValues.isCashOnDelivery
                  ? "<br/>+Utánvétes fizetés:1 * " +
                    formatHuf(formValues.cashOnDelivery)
                  : "") +
              (systemCost
                  ? "<br/>+Rendszerhasználati díj:1 * " +
                    formatHuf(multSystemCost)
                  : "") +
              (formValues.isBonusProduct
                  ? "<br/>+Bónusz termék ára:" +
                    formValues.quantity +
                    " * " +
                    formatHuf(formValues.bonusProduct) +
                    " = " +
                    formatHuf(multByQty(formValues.bonusProduct))
                  : "")
            : "+Csomagolás:" +
              formatHuf(formValues.packing) +
              "<br/>+Szállítási díj:" +
              formatHuf(formValues.shipping) +
              (formValues.isBonusProduct
                  ? "<br/>+Bónusz termék ára:" +
                    formatHuf(formValues.bonusProduct)
                  : "") +
              (formValues.isCreditCardPayment
                  ? "<br/>+Bankkártyás fizetés:" + formatHuf(creditCardPayment)
                  : "") +
              (formValues.isCashOnDelivery
                  ? "<br/>+Utánvétes fizetés:" +
                    formatHuf(formValues.cashOnDelivery)
                  : "") +
              (systemCost
                  ? "<br/>+Rendszerhasználati díj:" + formatHuf(systemCost)
                  : "");
        const tooltipFinalNetProfit = isMult()
            ? (formValues.isTransferPrice
                  ? "+Átadási ár:" +
                    formValues.quantity +
                    " * " +
                    formatHuf(formValues.netTransferPrice) +
                    " = " +
                    formatHuf(multByQty(formValues.netTransferPrice))
                  : "+Eladási ár:" +
                    formValues.quantity +
                    " * " +
                    formatHuf(netRetailPrice) +
                    " = " +
                    formatHuf(multByQty(netRetailPrice))) +
              "<br/>-Import ár:" +
              formValues.quantity +
              " * " +
              formatHuf(netImportPriceHuf) +
              " = " +
              formatHuf(multNetImportPriceHuf) +
              "<br/>-Költségek:1 * " +
              formatHuf(multSumCosts) +
              (multSumCommissions > 0
                  ? "<br/>-Jutalékok:1 * " + formatHuf(multSumCommissions)
                  : "")
            : (formValues.isTransferPrice
                  ? "+Átadási ár:" + formatHuf(formValues.netTransferPrice)
                  : "+Eladási ár:" + formatHuf(netRetailPrice)) +
              "<br/>-Import ár:" +
              formatHuf(netImportPriceHuf) +
              "<br/>-Költségek:" +
              formatHuf(sumCosts) +
              (sumCommissions > 0
                  ? "<br/>-Jutalékok:" + formatHuf(sumCommissions)
                  : "");
        const tooltipSumCommissions = isMult()
            ? (formValues.isReferralCommission &&
              formValues.referralCommission > 0
                  ? "+Kuponkód ajánlói jutalék:" +
                    formValues.quantity +
                    " * " +
                    formatHuf(formValues.referralCommission) +
                    " = " +
                    formatHuf(multByQty(formValues.referralCommission))
                  : "") +
              (formValues.isAcquisitionCommission &&
              formValues.acquisitionCommission
                  ? "<br/>+Szerzési jutalék:" +
                    formValues.quantity +
                    " * " +
                    formatHuf(formValues.acquisitionCommission) +
                    " = " +
                    formatHuf(multByQty(formValues.acquisitionCommission))
                  : "") +
              (formValues.isAcquisitionManagerCommission &&
              formValues.acquisitionManagerCommission
                  ? "<br/>+Szerzési vezetői jutalék:" +
                    formValues.quantity +
                    " * " +
                    formatHuf(formValues.acquisitionManagerCommission) +
                    " = " +
                    formatHuf(
                        multByQty(formValues.acquisitionManagerCommission)
                    )
                  : "") +
              (formValues.isMaintenanceCommission &&
              formValues.maintenanceCommission
                  ? "<br/>+Fenntartási jutalék:" +
                    formValues.quantity +
                    " * " +
                    formatHuf(formValues.maintenanceCommission) +
                    " = " +
                    formatHuf(multByQty(formValues.maintenanceCommission))
                  : "") +
              (formValues.isMaintenanceManagerCommission &&
              formValues.maintenanceManagerCommission
                  ? "<br/>+Fenntartási vezetői jutalék:" +
                    formValues.quantity +
                    " * " +
                    formatHuf(formValues.maintenanceManagerCommission) +
                    " = " +
                    formatHuf(
                        multByQty(formValues.maintenanceManagerCommission)
                    )
                  : "")
            : (formValues.isReferralCommission &&
              formValues.referralCommission > 0
                  ? "+Kuponkód ajánlói jutalék:" +
                    formatHuf(formValues.referralCommission)
                  : "") +
              (formValues.isAcquisitionCommission &&
              formValues.acquisitionCommission
                  ? "<br/>+Szerzési jutalék:" +
                    formatHuf(formValues.acquisitionCommission)
                  : "") +
              (formValues.isAcquisitionManagerCommission &&
              formValues.acquisitionManagerCommission
                  ? "<br/>+Szerzési vezetői jutalék:" +
                    formatHuf(formValues.acquisitionManagerCommission)
                  : "") +
              (formValues.isMaintenanceCommission &&
              formValues.maintenanceCommission
                  ? "<br/>+Fenntartási jutalék:" +
                    formatHuf(formValues.maintenanceCommission)
                  : "") +
              (formValues.isMaintenanceManagerCommission &&
              formValues.maintenanceManagerCommission
                  ? "<br/>+Fenntartási vezetői jutalék:" +
                    formatHuf(formValues.maintenanceManagerCommission)
                  : "");
        const updatedCalculatedValues = {
            netImportPriceHuf: netImportPriceHuf,
            netRetailPrice: netRetailPrice,
            grossTransferPrice: grossTransferPrice,
            netCommercialMargin: netCommercialMargin,
            netCommercialMarginEur: netCommercialMarginEur,
            commercialMarginPercent: commercialMarginPercent,
            creditCardPayment: creditCardPayment,
            systemCost: systemCost,
            sumCosts: sumCosts,
            sumCommissions: sumCommissions,
            finalNetProfit: finalNetProfit,
            multNetImportPriceHuf: multNetImportPriceHuf,
            multSumCommissions: multSumCommissions,
            multSystemCost: multSystemCost,
            multSumCosts: multSumCosts,
            multFinalNetProfit: multFinalNetProfit,
            tooltipSumCosts: tooltipSumCosts,
            tooltipFinalNetProfit: tooltipFinalNetProfit,
            tooltipSumCommissions: tooltipSumCommissions,
        };
        return updatedCalculatedValues;
    };

    const [calculatedValues, setCalculatedValues] =
        useState<CalculatedValuesType>(calculate());

    useEffect(() => {
        setCalculatedValues(calculate());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);

    useEffect(() => {
        setIsModelChanged(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedModel]);

    const handleImport = (jsonData: ModelType) => {
        const newModelsData = modelsDataWithIndex([
            ...orginalModelsData,
            jsonData,
        ]);
        setModelsData(newModelsData);
        setSelectedModel(newModelsData[newModelsData.length - 1]);
        setFormValues(newModelsData[newModelsData.length - 1].values);
    };

    const handleModelChange = (dropdownEvent: DropdownChangeEvent) => {
        const newModel: ModelType = dropdownEvent.value;
        setSelectedModel(newModel);
        setFormValues(newModel.values);
    };

    const handleFormInputChange =
        (fieldName: keyof FormValuesType) => (value: number) => {
            if (fieldName === "quantity" && value === 0) value = 1;
            const updatedFormValues: FormValuesType = {
                ...formValues,
                [fieldName]: value,
            };
            setFormValues(updatedFormValues);
            setIsModelChanged(
                !isEqual(selectedModel.values, updatedFormValues)
            );
        };

    const handleSwitchChange =
        (fieldName: keyof FormValuesType) =>
        (switchEvent: InputSwitchChangeEvent) => {
            let updatedFormValues: FormValuesType | undefined = undefined;
            if (
                fieldName === "isAcquisitionCommission" ||
                fieldName === "isAcquisitionManagerCommission"
            ) {
                updatedFormValues = {
                    ...formValues,
                    isAcquisitionCommission:
                        fieldName === "isAcquisitionCommission"
                            ? switchEvent.value
                            : formValues.isAcquisitionCommission,
                    isAcquisitionManagerCommission:
                        fieldName === "isAcquisitionManagerCommission"
                            ? switchEvent.value
                            : formValues.isAcquisitionManagerCommission,
                    isMaintenanceCommission: false,
                    isMaintenanceManagerCommission: false,
                };
            } else if (
                fieldName === "isMaintenanceCommission" ||
                fieldName === "isMaintenanceManagerCommission"
            ) {
                updatedFormValues = {
                    ...formValues,
                    isAcquisitionCommission: false,
                    isAcquisitionManagerCommission: false,
                    isMaintenanceCommission:
                        fieldName === "isMaintenanceCommission"
                            ? switchEvent.value
                            : formValues.isMaintenanceCommission,
                    isMaintenanceManagerCommission:
                        fieldName === "isMaintenanceManagerCommission"
                            ? switchEvent.value
                            : formValues.isMaintenanceManagerCommission,
                };
            } else if (fieldName === "isCreditCardPayment") {
                updatedFormValues = {
                    ...formValues,
                    isCreditCardPayment:
                        fieldName === "isCreditCardPayment"
                            ? switchEvent.value
                            : formValues.isCreditCardPayment,
                    isCashOnDelivery: false,
                };
            } else if (fieldName === "isCashOnDelivery") {
                updatedFormValues = {
                    ...formValues,
                    isCreditCardPayment: false,
                    isCashOnDelivery:
                        fieldName === "isCashOnDelivery"
                            ? switchEvent.value
                            : formValues.isCashOnDelivery,
                };
            } else {
                updatedFormValues = {
                    ...formValues,
                    [fieldName]: switchEvent.value,
                };
            }
            setFormValues(updatedFormValues);
            setIsModelChanged(
                !isEqual(selectedModel.values, updatedFormValues)
            );
        };
    return (
        <>
            <div className="w-full">
                {selectedModel ? (
                    <>
                        <div className="sticky top-0 z-5 shadow-1 surface-100">
                            <MultiProgressBar
                                values={
                                    isMult()
                                        ? [
                                              calculatedValues.multNetImportPriceHuf,
                                              calculatedValues.multSumCosts,
                                              calculatedValues.multSumCommissions,
                                              calculatedValues.multFinalNetProfit,
                                          ]
                                        : [
                                              calculatedValues.netImportPriceHuf,
                                              calculatedValues.sumCosts,
                                              calculatedValues.sumCommissions,
                                              calculatedValues.finalNetProfit,
                                          ]
                                }
                            />
                        </div>
                        <div className="p-2 mb-6 md:p-4 md:mb-4 lg:p-6 mb-2">
                            <h1 className="text-center text-xl line-height-2 md:text-3xl">
                                <div className="text-orange-500">
                                    {isModelChanged === true
                                        ? "[SZERKESZTETT] "
                                        : ""}
                                </div>
                                {selectedModel.name}
                            </h1>
                            {/* ÉRTÉKESÍTÉSI MODELL VÁLASZTÁS */}
                            <div className="card mt-3">
                                <label
                                    className="font-bold block mb-2"
                                    htmlFor="modalSelect"
                                >
                                    Modell választás
                                </label>
                                <Dropdown
                                    id="modalSelect"
                                    name="modalSelect"
                                    className="w-full"
                                    value={selectedModel}
                                    onChange={handleModelChange}
                                    options={modelsData}
                                    optionLabel="name"
                                    placeholder="Értékesítés modell választás"
                                />
                            </div>
                            {/* EXPORTÁLÁS & IMPORTÁLÁS */}
                            <div className="card flex gap-3 mt-3 text-center  justify-content-center md:text-left sm:justify-content-start">
                                <ExportModel formValues={formValues} />
                                <ImportModel onFileLoaded={handleImport} />
                            </div>
                            {/* ÖSSZEFOGLALÓ */}
                            <div className="card mt-3">
                                <Fieldset
                                    className="pb-2"
                                    legend={
                                        isMult()
                                            ? "Összefoglaló | " +
                                              formatQty(formValues.quantity)
                                            : "Összefoglaló"
                                    }
                                    toggleable
                                >
                                    <div className="grid mb-2">
                                        <div className="col-12 lg:col-4">
                                            <NumericLabel
                                                id="finalNetProfit"
                                                label={
                                                    calculatedValues.finalNetProfit <
                                                    0
                                                        ? "Veszteség"
                                                        : "Profit"
                                                }
                                                value={
                                                    isMult()
                                                        ? calculatedValues.multFinalNetProfit
                                                        : calculatedValues.finalNetProfit
                                                }
                                                tooltip={
                                                    calculatedValues.tooltipFinalNetProfit
                                                }
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericLabel
                                                id="sumCosts"
                                                label="Költségek"
                                                value={
                                                    isMult()
                                                        ? calculatedValues.multSumCosts
                                                        : calculatedValues.sumCosts
                                                }
                                                tooltip={
                                                    calculatedValues.tooltipSumCosts
                                                }
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericLabel
                                                id="sumCommissions"
                                                label="Jutalékok"
                                                value={
                                                    isMult()
                                                        ? calculatedValues.multSumCommissions
                                                        : calculatedValues.sumCommissions
                                                }
                                                tooltip={
                                                    calculatedValues.tooltipSumCommissions
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="grid mb-2">
                                        <div className="col-12 lg:col-4">
                                            <NumericLabel
                                                id="netCommercialMargin"
                                                label="Kereskedelmi árrés"
                                                value={multByQty(
                                                    calculatedValues.netCommercialMargin
                                                )}
                                                disabled={
                                                    !formValues.isTransferPrice
                                                }
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericLabel
                                                id="netCommercialMarginEur"
                                                label="Kereskedelmi árrés"
                                                value={multByQty(
                                                    calculatedValues.netCommercialMarginEur
                                                )}
                                                isEuro={true}
                                                disabled={
                                                    !formValues.isTransferPrice
                                                }
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericLabel
                                                id="commercialMarginPercent"
                                                label="Kereskedelmi árrés"
                                                value={
                                                    calculatedValues.commercialMarginPercent
                                                }
                                                isPercent={true}
                                                disabled={
                                                    !formValues.isTransferPrice
                                                }
                                            />
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            {/* ÁR */}
                            <div className="card mt-3">
                                <Fieldset
                                    legend="Ár"
                                    toggleable
                                >
                                    <div className="grid mb-2">
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="netImportPriceEur"
                                                onInputChange={handleFormInputChange(
                                                    "netImportPriceEur"
                                                )}
                                                label="Import ár"
                                                value={
                                                    formValues.netImportPriceEur
                                                }
                                                isEuro={true}
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="euroExchangeRate"
                                                onInputChange={handleFormInputChange(
                                                    "euroExchangeRate"
                                                )}
                                                label="€ árfolyam"
                                                value={
                                                    formValues.euroExchangeRate
                                                }
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericLabel
                                                id="netImportPriceHuf"
                                                label="Import ár"
                                                value={
                                                    calculatedValues.netImportPriceHuf
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="grossRetailPrice"
                                                onInputChange={handleFormInputChange(
                                                    "grossRetailPrice"
                                                )}
                                                label={
                                                    formValues.isTransferPrice
                                                        ? "Ajánlott kiskereskedelmi ár (bruttó)"
                                                        : "Eladási ár (bruttó)"
                                                }
                                                value={
                                                    formValues.grossRetailPrice
                                                }
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericLabel
                                                id="netRetailPrice"
                                                label={
                                                    formValues.isTransferPrice
                                                        ? "Ajánlott kiskereskedelmi ár"
                                                        : "Eladási ár"
                                                }
                                                value={
                                                    calculatedValues.netRetailPrice
                                                }
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="quantity"
                                                onInputChange={handleFormInputChange(
                                                    "quantity"
                                                )}
                                                onSwitchChange={handleSwitchChange(
                                                    "isQuantity"
                                                )}
                                                checked={formValues.isQuantity}
                                                label="Mennyiség"
                                                value={formValues.quantity}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 lg:col-4">
                                            <NumericLabel
                                                id="grossTransferPrice"
                                                label="Átadási ár (bruttó)"
                                                value={
                                                    calculatedValues.grossTransferPrice
                                                }
                                                disabled={
                                                    !formValues.isTransferPrice
                                                }
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="netTransferPrice"
                                                onInputChange={handleFormInputChange(
                                                    "netTransferPrice"
                                                )}
                                                label="Átadási ár"
                                                onSwitchChange={handleSwitchChange(
                                                    "isTransferPrice"
                                                )}
                                                checked={
                                                    formValues.isTransferPrice
                                                }
                                                value={
                                                    formValues.netTransferPrice
                                                }
                                            />
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            {/* KÖLTSÉGEK */}
                            <div className="card mt-3">
                                <Fieldset
                                    legend="Költségek"
                                    toggleable
                                >
                                    <div className="grid mb-2">
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="packing"
                                                label="Csomagolás"
                                                onInputChange={handleFormInputChange(
                                                    "packing"
                                                )}
                                                value={formValues.packing}
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="shipping"
                                                label="Szállítási díj"
                                                onInputChange={handleFormInputChange(
                                                    "shipping"
                                                )}
                                                value={formValues.shipping}
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="bonusProduct"
                                                label="Bónusz termék ára"
                                                onInputChange={handleFormInputChange(
                                                    "bonusProduct"
                                                )}
                                                onSwitchChange={handleSwitchChange(
                                                    "isBonusProduct"
                                                )}
                                                checked={
                                                    formValues.isBonusProduct
                                                }
                                                value={formValues.bonusProduct}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 lg:col-4">
                                            {/* Lehetőség van szerkeszthetővé tenni a mezőt, a logika teljesen kész,
                                            - onInputChange-et elérhetővé kell tenni
                                            - disabled kommentezni.
                                            Ha 0-át íródik a mezőben, ismét automatikusan számol a rendszer. */}
                                            <NumericInput
                                                id="creditCardPayment"
                                                label="Bankkártyás fizetés"
                                                // onInputChange={handleFormInputChange(
                                                //     "creditCardPayment"
                                                // )}
                                                onSwitchChange={handleSwitchChange(
                                                    "isCreditCardPayment"
                                                )}
                                                checked={
                                                    formValues.isCreditCardPayment
                                                }
                                                value={
                                                    calculatedValues.creditCardPayment
                                                }
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="cashOnDelivery"
                                                label="Utánvétes fizetés"
                                                onInputChange={handleFormInputChange(
                                                    "cashOnDelivery"
                                                )}
                                                onSwitchChange={handleSwitchChange(
                                                    "isCashOnDelivery"
                                                )}
                                                checked={
                                                    formValues.isCashOnDelivery
                                                }
                                                value={
                                                    formValues.cashOnDelivery
                                                }
                                                maxPriceError={
                                                    multByQty(
                                                        formValues.isTransferPrice
                                                            ? calculatedValues.grossTransferPrice
                                                            : formValues.grossRetailPrice
                                                    ) >
                                                    maxPriceCashOnDelivery.value
                                                }
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericLabel
                                                id="systemCost"
                                                label="Rendszerhasználati díj"
                                                value={
                                                    isMult()
                                                        ? calculatedValues.multSystemCost
                                                        : calculatedValues.systemCost
                                                }
                                            />
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            {/* JUTALÉKOK */}
                            <div className="card mt-3">
                                <Fieldset
                                    legend="Jutalékok"
                                    toggleable
                                >
                                    <div className="grid w-auto justify-content-between mb-2 lg:justify-content-start">
                                        <div className="col-12 lg:col-4 ">
                                            <NumericInput
                                                id="referralCommission"
                                                onInputChange={handleFormInputChange(
                                                    "referralCommission"
                                                )}
                                                onSwitchChange={handleSwitchChange(
                                                    "isReferralCommission"
                                                )}
                                                label="Kuponkód ajánlói jutalék"
                                                checked={
                                                    formValues.isReferralCommission
                                                }
                                                value={
                                                    formValues.referralCommission
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="acquisitionCommission"
                                                onInputChange={handleFormInputChange(
                                                    "acquisitionCommission"
                                                )}
                                                onSwitchChange={handleSwitchChange(
                                                    "isAcquisitionCommission"
                                                )}
                                                label="Szerzési jutalék"
                                                checked={
                                                    formValues.isAcquisitionCommission
                                                }
                                                value={
                                                    formValues.acquisitionCommission
                                                }
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="acquisitionManagerCommission"
                                                onInputChange={handleFormInputChange(
                                                    "acquisitionManagerCommission"
                                                )}
                                                onSwitchChange={handleSwitchChange(
                                                    "isAcquisitionManagerCommission"
                                                )}
                                                label="Szerzési vezetői jutalék"
                                                checked={
                                                    formValues.isAcquisitionManagerCommission
                                                }
                                                value={
                                                    formValues.acquisitionManagerCommission
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="maintenanceCommission"
                                                onInputChange={handleFormInputChange(
                                                    "maintenanceCommission"
                                                )}
                                                onSwitchChange={handleSwitchChange(
                                                    "isMaintenanceCommission"
                                                )}
                                                label="Fenntartási jutalék"
                                                checked={
                                                    formValues.isMaintenanceCommission
                                                }
                                                value={
                                                    formValues.maintenanceCommission
                                                }
                                            />
                                        </div>
                                        <div className="col-12 lg:col-4">
                                            <NumericInput
                                                id="maintenanceManagerCommission"
                                                onInputChange={handleFormInputChange(
                                                    "maintenanceManagerCommission"
                                                )}
                                                onSwitchChange={handleSwitchChange(
                                                    "isMaintenanceManagerCommission"
                                                )}
                                                label="Fenntartási vezetői jutalék"
                                                checked={
                                                    formValues.isMaintenanceManagerCommission
                                                }
                                                value={
                                                    formValues.maintenanceManagerCommission
                                                }
                                            />
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
            <div className="fixed bottom-0 right-0 z-5 shadow-1 p-1 pl-3 pr-3 text-center text-sm surface-100 text-gray-600 border-1 border-gray-200 border-round-top">
                Firssítve: {packageJson.lastUpdate} | Verzió:{" "}
                {packageJson.version}
            </div>
        </>
    );
};

export default App;
