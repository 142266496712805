const formatNumber = (input: number): string => {
    return new Intl.NumberFormat("de-DE", {
        style: "decimal",
        maximumFractionDigits: 0, // Maximum tizedesjegyek
    }).format(input);
};

const formatQty = (input: number): string => {
    return formatNumber(input) + " db";
};

const formatHuf = (input: number): string => {
    return formatNumber(input) + " Ft";
};

const arabicToRoman = (num: number): string => {
    const romanMap = [
        { value: 1000, symbol: "M" },
        { value: 900, symbol: "CM" },
        { value: 500, symbol: "D" },
        { value: 400, symbol: "CD" },
        { value: 100, symbol: "C" },
        { value: 90, symbol: "XC" },
        { value: 50, symbol: "L" },
        { value: 40, symbol: "XL" },
        { value: 10, symbol: "X" },
        { value: 9, symbol: "IX" },
        { value: 5, symbol: "V" },
        { value: 4, symbol: "IV" },
        { value: 1, symbol: "I" },
    ];
    let roman = "";
    romanMap.forEach((item) => {
        while (num >= item.value) {
            roman += item.symbol;
            num -= item.value;
        }
    });
    return roman;
};

const getUUID = (): string => {
    return Math.random().toString(36).substring(2, 12);
};

export { formatQty, formatHuf, arabicToRoman, getUUID };
