import React from "react";
import { Tooltip } from "primereact/tooltip";
import { InputNumber } from "primereact/inputnumber";
import NumericLabelPropsType from "../types/NumericLabelPropsType";

const NumericLabel: React.FC<NumericLabelPropsType> = ({
    id,
    label,
    value,
    tooltip,
    isEuro,
    isPercent,
    disabled,
}) => {
    const valueState: number = value !== undefined ? value : 0;
    const isTooltip: boolean =
        tooltip !== undefined && tooltip !== null && tooltip !== "";
    const tooltipLines = isTooltip ? tooltip.split("<br/>") : null;
    const isDisabled: boolean =
        disabled !== undefined && disabled !== null ? disabled : false;
    return (
        <div className="flex-auto">
            {isTooltip ? (
                <Tooltip
                    position="bottom"
                    target={".tooltip-" + id}
                >
                    <div className="text-xs md:text-lg"></div>
                    {tooltipLines.map((part: string, index: number) => {
                        const parts = part.split(":");
                        if (parts[0].length > 0) {
                            return (
                                <div
                                    key={index}
                                    className="flex justify-content-between"
                                >
                                    <div className="text-left mr-12">
                                        {parts[0] + ":"}
                                    </div>
                                    <div className="text-right">{parts[1]}</div>
                                </div>
                            );
                        } else {
                            return null;
                        }
                    })}
                </Tooltip>
            ) : null}
            {label && (
                <label
                    className="font-bold block mb-2"
                    htmlFor={id}
                >
                    {label}
                </label>
            )}
            <div className="p-inputgroup flex-1">
                <InputNumber
                    className={`w-full text-cyan-500 ${
                        tooltip && tooltip !== "" ? "tooltip-" + id : ""
                    }`}
                    inputId={id}
                    value={valueState}
                    locale="de-DE"
                    disabled={isDisabled === true ? false : true}
                    readOnly={isDisabled === true ? true : false}
                />
                {isEuro !== undefined && isEuro && (
                    <span className="p-inputgroup-addon">€</span>
                )}
                {isPercent !== undefined && isPercent && (
                    <span className="p-inputgroup-addon">%</span>
                )}
            </div>
        </div>
    );
};

export default NumericLabel;
