// https://apexcharts.com/
import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const MultiProgressBar: React.FC<{ values: number[] }> = ({ values }) => {
    const calculatePercentageDistribution = (numbers: number[]): number[] => {
        const total: number = numbers.reduce(
            (sum, num) => sum + Math.abs(num),
            0
        );
        const percentages: number[] = numbers.map((num) =>
            Math.ceil((num / total) * 100)
        );
        percentages[3] =
            100 - percentages.slice(0, 3).reduce((sum, num) => sum + num, 0);
        percentages[3] = values[3] < 0 ? -percentages[3] : percentages[3];
        return percentages;
    };

    const [valuesPercentageDistribution, setValuesPercentageDistribution] =
        useState<number[]>(calculatePercentageDistribution(values));

    const chartColors = {
        importPrice: "#3498db", // Kék
        costs: "#8e44ad", // Lila
        commissions: "#f39c12", // Narancs
        profit: "#2ecc71", // Zöld
        negativeProfit: "#ea4025", // Piros
    };

    const series: ApexOptions["series"] = [
        {
            name: `Import Ár [${valuesPercentageDistribution[0]}%]`,
            data: [valuesPercentageDistribution[0]],
            color: chartColors.importPrice,
        },
        {
            name: `Költségek [${valuesPercentageDistribution[1]}%]`,
            data: [valuesPercentageDistribution[1]],
            color: chartColors.costs,
        },
        {
            name: `Jutalékok [${valuesPercentageDistribution[2]}%]`,
            data: [valuesPercentageDistribution[2]],
            color: chartColors.commissions,
        },
        {
            name: `Profit [${valuesPercentageDistribution[3]}%]`,
            data: [valuesPercentageDistribution[3]],
            color: chartColors.profit,
        },
    ];

    const seriesNegativeProfit: ApexOptions["series"] = [
        {
            name: "Veszteség",
            data: [100],
            color: chartColors.negativeProfit,
        },
    ];
    useEffect(() => {
        setValuesPercentageDistribution(
            calculatePercentageDistribution(values)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return (
        <div>
            <ReactApexChart
                className="p-0 m-0"
                type="bar"
                height={80}
                series={values[3] < 0 ? seriesNegativeProfit : series}
                options={{
                    chart: {
                        stacked: true,
                        stackType: "100%",
                        toolbar: {
                            show: false,
                        },
                        offsetX: 0,
                        offsetY: 0,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            columnWidth: "100%",
                        },
                    },
                    stroke: {
                        width: 1,
                    },

                    xaxis: {
                        labels: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                    },
                    yaxis: {
                        labels: {
                            show: false,
                        },
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                    },
                    legend: {
                        show: true,
                        showForSingleSeries: true,
                        horizontalAlign: "center",
                        position: "bottom",
                    },
                    tooltip: {
                        enabled: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    grid: {
                        show: true,
                        xaxis: {
                            lines: {
                                show: false,
                            },
                        },
                        yaxis: {
                            lines: {
                                show: false,
                            },
                        },
                    },
                }}
            />
        </div>
    );
};

export default MultiProgressBar;
