import React, { useRef } from "react";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { ImportModelProps } from "../types/ImportModelPropsType";
import FormValuesType from "../types/FormValuesType";

const ImportModel: React.FC<ImportModelProps> = ({ onFileLoaded }) => {
    const fileUploadRef = useRef<FileUpload>(null);
    const toast = useRef<Toast>(null);

    const isValidFormValuesType = (values: any): values is FormValuesType =>
        typeof values.netImportPriceEur === "number" &&
        typeof values.euroExchangeRate === "number" &&
        typeof values.grossRetailPrice === "number" &&
        typeof values.netTransferPrice === "number" &&
        typeof values.quantity === "number" &&
        typeof values.packing === "number" &&
        typeof values.shipping === "number" &&
        typeof values.cashOnDelivery === "number" &&
        typeof values.bonusProduct === "number" &&
        typeof values.creditCardPayment === "number" &&
        typeof values.referralCommission === "number" &&
        typeof values.acquisitionCommission === "number" &&
        typeof values.acquisitionManagerCommission === "number" &&
        typeof values.maintenanceCommission === "number" &&
        typeof values.maintenanceManagerCommission === "number" &&
        typeof values.isTransferPrice === "boolean" &&
        typeof values.isQuantity === "boolean" &&
        typeof values.isCashOnDelivery === "boolean" &&
        typeof values.isCreditCardPayment === "boolean" &&
        typeof values.isBonusProduct === "boolean" &&
        typeof values.isReferralCommission === "boolean" &&
        typeof values.isAcquisitionCommission === "boolean" &&
        typeof values.isAcquisitionManagerCommission === "boolean" &&
        typeof values.isMaintenanceCommission === "boolean" &&
        typeof values.isMaintenanceManagerCommission === "boolean";

    const validateModel = (jsonData: any): jsonData is ModelType =>
        typeof jsonData.name === "string" &&
        typeof jsonData.code === "string" &&
        isValidFormValuesType(jsonData.values);

    const onUpload = (event: { files: File[] }) => {
        const file = event.files[0];
        if (!file) {
            toast.current?.show({
                severity: "error",
                summary: "Hiba",
                detail: "Nincs fájl kiválasztva.",
            });
            return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target?.result;
            try {
                const json = JSON.parse(content as string);
                // Egyszerű ellenőrzés, hogy a fájl nem üres és JSON formátumú
                if (Object.keys(json).length > 0) {
                    if (validateModel(json)) {
                        onFileLoaded(json);
                        toast.current?.show({
                            severity: "success",
                            summary: "Siker",
                            detail: "Modell sikeresen importálva.",
                        });
                    } else {
                        toast.current?.show({
                            severity: "error",
                            summary: "Hiba",
                            detail: "Érvénytelen JSON modell fájl.",
                        });
                    }
                } else {
                    toast.current?.show({
                        severity: "error",
                        summary: "Hiba",
                        detail: "Üres vagy érvénytelen JSON modell fájl.",
                    });
                }
            } catch (error) {
                toast.current?.show({
                    severity: "error",
                    summary: "Hiba",
                    detail: "Hiba a JSON fájl beolvasása során: " + error,
                });
            }
            fileUploadRef.current?.clear();
        };
        reader.readAsText(file);
    };

    return (
        <>
            <Toast ref={toast}></Toast>
            <FileUpload
                ref={fileUploadRef}
                customUpload
                uploadHandler={onUpload}
                accept=".json"
                mode="basic"
                auto
                chooseLabel="Importálás"
            />
        </>
    );
};

export default ImportModel;
