import FormValuesType from "../types/FormValuesType";

const exportModel = (data: ModelType, fileName: string = "new-modal.json") => {
    const jsonString = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};

const importModal = (callback: (data: FormValuesType) => void) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".json";

    input.onchange = (e) => {
        const file = (e.target as HTMLInputElement).files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const text = e.target?.result;
                const data = JSON.parse(text as string);
                callback(data);
            } catch (error) {
                console.error("Error parsing JSON:", error);
                alert("Hiba történt a fájl olvasása közben.");
            }
        };
        reader.readAsText(file);
    };

    input.click();
};

export { exportModel, importModal };
