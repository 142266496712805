import React, { useEffect, useState } from "react";
import {
    InputNumber,
    InputNumberValueChangeEvent,
} from "primereact/inputnumber";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import NumericInputPropsType from "../types/NumericInputPropsType";
import { formatHuf } from "../utils/stringUtils";
import maxPriceCashOnDelivery from "../datas/maxPriceCashOnDelivery.json";

const NumericInput: React.FC<NumericInputPropsType> = ({
    id,
    label,
    onInputChange,
    onSwitchChange,
    value,
    isEuro,
    checked,
    disabled,
    maxPriceError,
}) => {
    const [valueState, setValueState] = useState<number>(value ? value : 0);
    const [checkedState, setCheckedState] = useState<boolean>(
        checked !== undefined ? checked : true
    );

    const handleInputChange = (event: InputNumberValueChangeEvent) => {
        const newValue: number =
            event.target.value !== undefined && event.target.value !== null
                ? event.target.value
                : 0;
        setValueState(newValue);
        if (onInputChange !== undefined) {
            onInputChange(newValue);
        }
    };

    const handleSwitchChange = (switchEvent: InputSwitchChangeEvent) => {
        const newValue = switchEvent.value ? valueState : 0;
        if (onInputChange !== undefined) {
            onInputChange(newValue);
        }
        setCheckedState(switchEvent.value);
        if (onSwitchChange !== undefined) {
            onSwitchChange(switchEvent);
        }
    };

    useEffect(() => {
        if (checked !== undefined) {
            setCheckedState(checked);
        }
    }, [checked]);

    useEffect(() => {
        if (value !== undefined) {
            setValueState(value);
        }
    }, [value]);

    return (
        <div className="flex-auto w-auto">
            {label && (
                <label
                    className="font-bold block mb-2"
                    htmlFor={id}
                >
                    {label}
                </label>
            )}
            <div className="p-inputgroup flex-1">
                {checked !== undefined && (
                    <span className="p-inputgroup-addon">
                        <InputSwitch
                            className="z-4"
                            checked={checkedState}
                            onChange={handleSwitchChange}
                        />
                    </span>
                )}
                <InputNumber
                    className={
                        "w-full" +
                        (maxPriceError !== undefined &&
                        maxPriceError === true &&
                        checkedState
                            ? " p-invalid"
                            : "")
                    }
                    inputId={id}
                    value={valueState}
                    onValueChange={handleInputChange}
                    readOnly={!checkedState}
                    locale="de-DE"
                    disabled={
                        disabled !== undefined &&
                        checked !== undefined &&
                        checked
                            ? disabled
                            : false
                    }
                />
                {isEuro !== undefined && isEuro && (
                    <span className="p-inputgroup-addon">€</span>
                )}
            </div>
            {maxPriceError !== undefined &&
            maxPriceError === true &&
            checkedState ? (
                <div className="tex-sm text-red-500 mt-1">
                    <span>
                        A maximum megengedett utánvétes fizetési összeg:{" "}
                    </span>
                    <span className="flex flex-nowrap">
                        {formatHuf(maxPriceCashOnDelivery.value)}
                    </span>
                </div>
            ) : null}
        </div>
    );
};

export default NumericInput;
